:root {
  --blue: #088BF7;
  --white: #ffffff;
  --green: #58B9A4;
  --darkblue: #045AA1;
  --yellow: #F9D438;
  --red: #c3423f;
}

html, body {
    height: 100%;
}


body {
    display: flex;
    flex-direction: column;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.root {
    flex: 1 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.formTool .btn {
  border-radius: 8px !important;
  min-width: 100px;
}

ul.nav-pills {
  position: fixed;
}

.panel-body {
  padding: 10px 15px;
}

.table {
  margin-bottom: 0;
}

.row {
  margin-top: 5px;
}

span.danger {
  color: #e74c3c
}

.min-chars-header {
  width: 90%;
  cursor: pointer;
}

.min-chars-body {
  width: 90%;
  cursor: pointer;
}

.accordion-button {
  padding: 6px;
}

.accordion-body {
  padding: 5px;
}

.border {
  margin-bottom: 10;
  border-bottom: grey solid 1px;
}

.react-datetime-picker {
  background-color: white;
}

  body {
    font: 400 15px Lato, sans-serif;
    line-height: 1.8;
    color: black;
  }
  h2 {
    font-size: 24px;
    text-transform: uppercase;
    color: #303030;
    font-weight: 600;
  }
  h4 {
    font-size: 19px;
    line-height: 1.375em;
    color: #303030;
    font-weight: 400;
  }  
  .jumbotron {
    background-color: #dfcfb8;
    color: #000;
    font-family: Montserrat, sans-serif;
    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
  }
  form {
    padding: 10px;
  }
  .bg-grey {
    background-color: #f6f6f6;
  }
  .logo-small {
    color: #dfcfb8;
    font-size: 50px;
  }
  .logo {
    color: #dfcfb8;
    font-size: 200px;
  }
  .thumbnail {
    padding: 0 0 15px 0;
    border: none;
    border-radius: 0;
  }
  .thumbnail img {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }
  .carousel-control.right, .carousel-control.left {
    background-image: none;
    color: #dfcfb8;
  }
  .carousel-indicators li {
    border-color: #dfcfb8;
  }
  .carousel-indicators li.active {
    background-color: #dfcfb8;
  }
  .item h4 {
    font-size: 19px;
    line-height: 1.375em;
    font-weight: 400;
    font-style: italic;
    margin: 70px 0;
  }
  .item span {
    font-style: normal;
  }
  .panel {
    border: 3px solid #dfcfb8; 
    border-radius: 10px !important;
    transition: box-shadow 0.5s;
  }
  .panel:hover {
    box-shadow: 5px 0px 40px rgba(0,0,0, .2);
  }
  .panel-heading {
    color: #000 !important;
    background-color: #dfcfb8 !important;
  }
  .navbar {
    margin-bottom: 0;
    background-color: #dfcfb8;
    border: 0;
    font-size: 12px !important;
    line-height: 1 !important;
    letter-spacing: 4px;
    border-radius: 0;
    font-family: Montserrat, sans-serif;
    height: 5vh;
    padding-left: 2%;
    padding-right: 2%;
  }
  .navbar li a, .navbar .navbar-brand {
    color: #000 !important;
    cursor: pointer;
  }
  .navbar-nav li a:hover, .navbar-nav li.active a {
    color: #dfcfb8 !important;
    background-color: #000 !important;
    cursor: pointer;
  }
  .navbar-default .navbar-toggle {
    border-color: transparent;
    color: #000 !important;
  }
  .slideanim {visibility:hidden;}
  .slide {
    animation-name: slide;
    -webkit-animation-name: slide;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    visibility: visible;
  }
  @keyframes slide {
    0% {
      opacity: 0;
      transform: translateY(70%);
    } 
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @-webkit-keyframes slide {
    0% {
      opacity: 0;
      -webkit-transform: translateY(70%);
    } 
    100% {
      opacity: 1;
      -webkit-transform: translateY(0%);
    }
  }
  @media screen and (max-width: 768px) {
    .col-sm-4 {
      text-align: center;
      margin: 25px 0;
    }
    .btn-lg {
      width: 100%;
      margin-bottom: 35px;
    }
  }
  @media screen and (max-width: 480px) {
    .logo {
      font-size: 150px;
    }
  }

  .affix {
    top: 20%;
    right: 0;
  }

  .center {
    text-align: center;
    justify-content: center;
  }

.loadingSpinner {
  position: absolute;
  top: 45vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.form-label {
    color: 'var(--bs-secondary)'
}

.nav-link {
  color: #045AA1 !important;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #045AA1 !important;
  color: white !important;
}

.BuilderModal {
  z-index: 99;
}

.BuilderModal .ReactModal__Content--after-open {
  padding: 0px !important;
  max-width: 1250px;
  margin: auto;
}

.BuilderModalHeader {
  height: 40px;
  width: 100%;
  padding: 8px;
  color: var(--white)
}

.Visibility .BuilderModalHeader {
  background-color: var(--blue);
}

.Required .BuilderModalHeader {
  background-color: var(--darkblue);
}

.Validations .BuilderModalHeader {
  background-color: var(--yellow);
}

.Interpretations .BuilderModalHeader {
  background-color: var(--green);
}

.QrCode .BuilderModalHeader {
  background-color: var(--darkblue);
}

.BuilderModalContent {
  padding: 20px
}

/** TipTap */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }
}

.mention {
  border: 1px solid #000;
  border-radius: 0.4rem;
  box-decoration-break: clone;
  padding: 0.1rem 0.3rem;
}
.items {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.9rem;
  overflow: scroll;
  padding: 0.2rem;
  position: relative;
  height: 200px;
}

.item {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  display: block;
  margin: 0;
  padding: 0.2rem 0.4rem;
  text-align: left;
  width: 100%;

  &.is-selected {
    border-color: #000;
  }
}

.EmailTipTapEditor {
  border: #000 solid 1px;
  border-radius: 10px;
}

.EmailTipTapEditor .tiptap {
  padding: 5px;
  margin: 5px;
}

.EmailTipTapEditorHeader {
  border-bottom: #000 solid 1px;
  padding: 10px;
  background-color: whitesmoke;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.EmailTipTapEditorHeader button {
  padding-right: 4px;
  padding-left: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.EmailTipTapEditorHeader .btn-group {
  margin: 3px;
}

.EmailTipTapEditorHeader button.is-active {
  background-color: var(--green);
}

.TipTapEditor {
  border: #000 solid 3px;
  border-radius: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  margin: 10px;
}

.question_reference,
.email_template_reference,
.company_reference {
  border: 1px solid black;
  border-radius: 5px;
  padding-left: 4px;
  padding-right: 4px;
  color: black;
}

/** Builder Tools */

.ReferenceEquation {
  margin: 6px;
  padding: 4px;
  border-bottom: #000 dashed 1px;
}

.ReferenceEquation input, .ReferenceEquation select {
  margin-left: 10px;
}

.formTool div.mb-3 {
  margin-bottom: 25px !important;
}

.formTool div.mb-3 input[type="text"],
.formTool div.mb-3 select,
.formTool div.mb-3 input[type="number"] {
  width: 100%;
  border-radius: 8px;
  border-width: 1px;
  border-color: grey;
  padding: 5px;
  padding-left: 10px;
  height: 35px;
}

.formTool div.mb-3 input[type="radio"] {
  width: 15px;
  height: 15px;
}

.formTool label.formikLabel {
  color: black;
  font-weight: 600;
}

.formTool .pageSummary strong {
  font-size: xx-large;
  font-weight: 800;
}

.input-group .rounded .react-datetime-picker__wrapper {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.btn.btn-success:hover {
  background-color: var(--green);
}

.BuilderModal .ReactModal__Content--after-open {
  inset: 40px 40px auto !important;
  max-height: calc(100% - 80px);
}

.QrModal .ReactModal__Content--after-open {
  inset: 40px calc((100% - 300px) / 2) auto !important;
  max-height: calc(100% - 80px);
}

.QrModal {
  z-index: 99;
}

.QrModal .ReactModal__Content--after-open {
  padding: 0px !important;
  max-width: 1250px;
  margin: auto;
}

.QrModalHeader {
  height: 40px;
  width: 100%;
  padding: 8px;
  color: var(--white);
  background-color: var(--darkblue);
}

.QrModalContent {
  padding: 20px
}

.SubmissionModal .ReactModal__Content--after-open {
  inset: 40px 40px auto !important;
  min-height: 500px;
  max-height: calc(100% - 80px);
}

.SubmissionModal {
  z-index: 99;
}

.SubmissionModal .ReactModal__Content--after-open {
  padding: 0px !important;
  max-width: 1250px;
  margin: auto;
  height: 100%;
}

.SubmissionModalHeader {
  height: 40px;
  width: 100%;
  padding: 8px;
  color: var(--white);
  background-color: var(--darkblue);
}

.SubmissionModalContent {
  padding: 20px;
  height: calc(100% - 40px);
}

.ContactModal .ReactModal__Content--after-open {
  inset: 40px 40px auto !important;
  min-height: 500px;
  max-height: calc(100% - 80px);
}

.ContactModal {
  z-index: 99;
}

.ContactModal .ReactModal__Content--after-open {
  padding: 0px !important;
  max-width: 1250px;
  margin: auto;
}

.ContactModalHeader {
  height: 40px;
  width: 100%;
  padding: 8px;
  color: var(--white);
  background-color: var(--darkblue);
}

.ContactModalContent {
  padding: 20px;
  height: calc(100% - 40px);
}