@use '../scss/custom.scss';

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn {
    font: normal normal normal 16px/22px Open Sans;
    letter-spacing: 1.28px;
    
}

.list-group-item {
    border: none;
    cursor: pointer;
}

.offcanvas-backdrop.show {
  opacity: .2;
}

div.ag-root-wrapper {
  border-radius: 10px;
}